<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="140px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="发票申请金额：" prop="amount">
                <p class="view-cont">{{ ruleForm.amount }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="发票抬头：" prop="bodyName">
                <p class="view-cont">{{ ruleForm.bodyName }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="税号：" prop="creditCode">
                <p class="view-cont">{{ ruleForm.creditCode }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="接收发票手机：" prop="phone">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.phone }}</p>
                <el-input clearable v-else v-model="ruleForm.phone" placeholder="请输入接收发票手机" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="短信验证码：" prop="verificationCode">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.verificationCode }}</p>
                <el-input v-else :class="sendFlag ? 'my-input-btn' :  'my-input-btn-gray'" v-model="ruleForm.verificationCode" placeholder="请输入手机验证码">
                  <el-button slot="append" v-if="sendFlag" @click="sendMsg('phone')" type="primary">获取验证码</el-button>
                  <el-button slot="append" v-else class="btn-gray"  type="primary">{{countdown}}秒后重新发送</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <p class="tips-gray">验证码10分钟有效</p>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="接收发票邮箱：" prop="email">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.email }}</p>
                <el-input clearable v-else v-model="ruleForm.email" placeholder="请输入接收发票邮箱" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.remark }}</p>
                <el-input clearable v-else v-model="ruleForm.remark" placeholder="请输入备注" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
let _t = null
export default {
  name: "invoiceEdit",
  components: {
  },
  props:{
  },
  data() {
    return {   
      rules: {
        verificationCode: [{ required: true,  trigger: "change", message: '请输入短信验证码'}],
        phone: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入手机号"))
            }else if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
        email: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入接收发票邮箱"))
            }else if(val && !baseValidate.email(val)){
              callback(new Error("请输入正确的邮箱"))
            }else{
              callback()
            }
          }
        }],
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {
        amount: '',
        phone: '',
        remark: '',
        email: '',
        verificationCode: ''
      },
      InitForm: {
      },
      countdown: CONSTPARAMS.countdown,
      sendFlag: true,
    };
  },
  created() {
	},
  mounted(){
    _t = this
  },
  methods: {
    sendMsg(prop){
      utils.sendMsg(this, prop, 'invoice')
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      let obj = {
        ...this.initForm,
        ...ruleForm,
        bodyName: utils.getBodyName(),
        creditCode: utils.getBodyInfo('creditCode')
      }
      this.ruleForm = obj
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    async submitForm() {
      let _this = this,
        formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitData()
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    async submitData(){
      let _this = this,
        contObj = this.ruleForm,
        editType = this.editType

      _this.loading = true
      let flag = await utils.codeCheck('invoice', contObj.phone, contObj.verificationCode) || false
      if(!flag) {
        _this.loading = false 
        return
      }
      let ways = editType == 'add' ? 'invoicesave' : 'invoicesave',
        target = {
        ...contObj,
        bodyId: utils.getBodyId(),
        bodyName: utils.getBodyName(),
        creditCode: utils.getBodyInfo('creditCode')
      }
      request[ways](target).then((res) => {
        if(res.code == 200){
          _this.message('新增成功！', "success")
          _this.$emit("getData", editType == 'add' ? true : false)
          _this.closeModel()
        }else{
          _this.message(res.msg || '', "error")
        }
        _this.loading = false
      }).catch(e => {
        _this.loading = false
      })
    },
    closeModel() {
      this.closeFlag = false
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
.tips-gray{
  padding-left: 42px;
}
</style>


