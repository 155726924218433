<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="46">
        </el-table-column>
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="updateStatus(scope.row, scope.$index)">{{ scope.row.status == '01' ? '禁用' :  '启用' }}</el-button>
            <el-button size="small" type="text" @click="resetpw(scope.row, scope.$index)">重置密码</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <invoice-edit ref="editForm" @getData="getData"></invoice-edit>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import invoiceEdit from './invoiceEdit.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'invoiceList',
    components: {
      pagination,
      breadcrumb,
      invoiceEdit
    },
    data() {
      let title = '发票管理'
      return {
        allSelData: [],
        breadStaticData:['系统管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId()
        },
        urlName: 'invoicelist',
        tableHead: [{
          prop: 'electronicLabelOrderCode',
          label: '订单号',
        },{
          prop: 'orderMoney',
          label: '金额'
        },{
          prop: 'payTime',
          label: '日期'
        }],
        initForm: {
        },
        loadtext: '加载中',
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        },
        multipleSelection: [],
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
      // this.$alert(`您的新密码为：${this.bornPW()}`, "", {
      //   confirmButtonText: "确定",
      //   type: "success",
      // })
      // .then(() => {
      // }).catch(() => {})
    },
    methods: {
      handleSelectionChange(val){
        this.multipleSelection = val
      },
      async initTable(){
        this.getData()
      },
      async add(){
        if (this.multipleSelection.length) {
          let orderNos = [], amount = 0
          this.multipleSelection.forEach(cur => {
            orderNos.push(cur.electronicLabelOrderCode)
            amount += parseFloat(cur.orderMoney)
          })
          let obj = {
            ...this.initForm,
            orderNo: orderNos.join(','),
            amount: utils.getFixedNumber(amount, 2, false)
          }
          this.$refs.editForm.initData(`新增发票`, 'add', obj)
        }else{
          this.message('请选择记录', 'warning')
        }

      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.apfindqualification({
          unitQualificationFileId: data.unitQualificationFileId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              unitQualificationFileUrl: result.unitQualificationFileUrl ? result.unitQualificationFileUrl.split(',') : [],
              issueTime: result.issueTime ? utils.dateFormat(result.issueTime, 'yyyy-MM-dd') : '',
              abortTime: result.abortTime ? utils.dateFormat(result.abortTime, 'yyyy-MM-dd') : ''
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      updateStatus(data, index){
        let _this = this
        _this.$confirm(`确认要${data.status == '01' ? '禁用' : '启用'}${data.userName || ''}这账号吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.updateaccount({
            userName: data.userName,
            status: data.status == '01' ? '02' : '01'
          }, 'status').then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message(`${data.status == '01' ? '禁用' : '启用'}成功！`, "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      bornPW(){
        let pwLen = 6,
          item = []
        for(let i = 0; i < pwLen; i++){
          item[i] = parseInt(Math.random() * 10);
        }
        return item.join('')
      },
      resetpw(data){
        let newpw = this.bornPW(),
          _this = this
        request.updateaccount({
          userName: data.userName,
          password: newpw
        }, 'pass').then(res => {
          if(res.code == 200){
            _this.$alert(`重置密码成功，您的新密码为：${newpw}，请牢记！`, "", {
              confirmButtonText: "确定",
              type: "success",
            })
            .then(() => {
            }).catch(() => {})
          }else{
            _this.message(res.msg || '', "error");
          }
        }).catch(e => {
          console.log(e)
        })
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                statusName: item.status == '01' ? '启用' : '禁用'
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>